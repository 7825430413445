import { LoginFormFieldValues as FormFieldValues } from '@/types/form'
import { TextField } from '@dmm-com/turtle-components'
import { ComponentProps, HTMLInputTypeAttribute } from 'react'
import { FieldErrors, UseFormRegister, ValidationRule } from 'react-hook-form'

// NOTE: TextFieldのPropsはoptionalにしているため必要であればoverrideしてください
interface Props extends Partial<ComponentProps<typeof TextField>> {
  useFormProps: {
    register: UseFormRegister<FormFieldValues>
    errors: FieldErrors<FormFieldValues>
  }
  name: keyof FormFieldValues
  errorText?: string

  // override
  label: string
  placeholder: string
  pattern?: ValidationRule<RegExp>
  type?: HTMLInputTypeAttribute | undefined
}

export const LoginTextFiled = ({
  useFormProps: { register, errors },
  errorText,
  name,
  label,
  placeholder,
  pattern,
  type,
  ...rest
}: Props) => {
  const loginTextFieldRegister = register(name, { pattern })
  const loginTextFieldErrorText = errors[name]?.message || errorText

  return (
    <TextField
      inputProps={{
        name: loginTextFieldRegister.name,
        ref: loginTextFieldRegister.ref,
        onBlur: loginTextFieldRegister.onBlur,
        type,
      }}
      errorText={loginTextFieldErrorText}
      state={loginTextFieldErrorText ? 'error' : undefined}
      label={label}
      placeholder={placeholder}
      {...rest}
    />
  )
}
